.img-brand
    max-height: 50px

.uk-UkPaper
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 32px;

.uk-UkPaper-Input
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 1;
    border-radius: 4px;
    border-color: rgba(0,0,0,0.2);
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
.img-ukcom
    width: 35vw
.img-uofu
    width: 10vw
.img-colu
    width: 15vw
.img-psh
    width: 20vw
.img-uvcom
    width: 20vw
.nav-style
    text-decoration: 'none'
    color: 'inhert'
